import { EnvironmentUrls, Endpoints } from 'Roblox';
import catalogModule from '../catalogModule';

const catalogConstants = {
  layout: {
    initialized: false,
    isNavigationMenuLoaded: false,
    isSearchItemsLoaded: false,
    customText: 'custom',
    accordionMenuIdPrefix: '#category-',
    showNoResultsMessage: false,
    showErrorMessage: false,
    isSearchOptionsOpen: false,
    isSearchBarFixed: false,
    loading: false,
    adsInitialized: false,
    showFirstLast: false,
    isItemDetailsLoaded: false,
    isAssetThumbnailLoaded: false,
    isBundleThumbnailLoaded: false,
    isItemDetailsLoadingFailed: false,
    preventLocationChange: false
  },
  defaults: {
    subcategory: '0'
  },
  /* for detail, examine item in $scope.data.categories, or endpoint /catalog/search-options
   * for each **category** in categories, Id => category.Category.Data, Name => category.Category.Description
   * subcategories, if any, are stored in array Item.Subcategories, whose Id and Name mapping is similar
   * to that for **category**
   *
   * note: **key** is not guaranteed to present as a property for a **subcategory**, do not rely on it!
   */
  allGearSubcategoryId: '0',
  itemType: {
    asset: 1,
    bundle: 2
  },

  templateUrls: {
    cardItemTemplate: 'item-card',
    catalogBreadcrumbsTemplate: 'catalog-breadcrumbs',
    creatorFiltersTemplate: 'creator-filters',
    mobileSearchBarTemplate: 'mobile-search-bar',
    catalogPageTemplate: 'catalog-page',
    mobileSearchOptionsTemplate: 'mobile-search-options',
    priceFiltersTemplate: 'price-filters',
    searchBarTemplate: 'search-bar',
    searchOptionsTemplate: 'search-options',
    showUnavailableFilterTemplate: 'show-unavailable-filter',
    itemsContainer: 'items-container',
    shimmerContainer: 'shimmer-container',
    shimmerMenu: 'shimmer-menu',
    salesTypeFilterTemplate: 'sales-type-filter',
    sortFilterTemplate: 'sort-filter'
  },

  buyRobuxUrl: `${EnvironmentUrls.websiteUrl}/upgrades/robux?ctx=catalogNew`,
  catalogUrl: `${EnvironmentUrls.websiteUrl}/catalog`,
  numberOfSearchItems: 30,
  numberOfSearchItemsForFullScreen: 60,
  numberOfSearchItemsExpanded: 120,
  expandedCategoryList: ['Recommended'],
  endpoints: {
    getMetadata: {
      url: `${EnvironmentUrls.catalogApi}/v1/catalog/metadata`,
      retryable: true,
      withCredentials: true
    },
    enrollAbTesting: {
      url: `${EnvironmentUrls.abtestingApiSite}/v1/enrollments`,
      retryable: false,
      withCredentials: true,
      timeout: 500
    },
    getPageItems: {
      url: Endpoints ? Endpoints.getAbsoluteUrl('/catalog/items') : '/catalog/items'
    },
    getSearchOptionsUrl: {
      url: Endpoints
        ? Endpoints.getAbsoluteUrl('/catalog/search-options')
        : '/catalog/search-options'
    },
    getNavigationMenuItems: {
      url: `${EnvironmentUrls.catalogApi}/v1/search/navigation-menu-items`,
      retryable: true
    },
    getSearchItems: {
      url: `${EnvironmentUrls.catalogApi}/v1/search/items`,
      retryable: true
    },
    getCatalogItemDetails: {
      url: `${EnvironmentUrls.catalogApi}/v1/catalog/items/details`,
      retryable: false,
      withCredentials: true
    },
    getCatalogThumbnails: {
      url: `${EnvironmentUrls.thumbnailsApi}/v1/assets?format=Png`, // we only have png
      retryable: true,
      withCredentials: true
    },
    getBundleThumbnails: {
      url: `${EnvironmentUrls.thumbnailsApi}/v1/bundles/thumbnails?format=Png`,
      retryable: true,
      withCredentials: true
    },
    performanceMeasurements: {
      url: `${EnvironmentUrls.metricsApi}/v1/performance/measurements`,
      retryable: false,
      withCredentials: true
    },
    avatarRequestSuggestion: {
      url: `${EnvironmentUrls.apiGatewayUrl}/autocomplete-avatar/v2/suggest`,
      withCredentials: true,
      timeout: 5000
    },
    avatarRequestCdnSuggestion: {
      url: `${EnvironmentUrls.apiGatewayCdnUrl}/autocomplete-avatar/v2/suggest`,
      withCredentials: true,
      timeout: 5000
    },
    postGetTopics: {
      url: `${EnvironmentUrls.catalogApi}/v1/topic/get-topics`,
      withCredentials: true,
      timeout: 5000
    },
    getUserCurrency: {
      url: '/v1/users/{userId}/currency',
      withCredentials: true,
      timeout: 5000
    },
    postMarketplaceItemDetails: {
      url: `${EnvironmentUrls.apiGatewayUrl}/marketplace-items/v1/items/details`,
      retryable: false,
      withCredentials: true
    }
  },
  getResellerDataUrl: assetId =>
    `${EnvironmentUrls.economyApi}/v1/assets/${assetId}/resellers?limit=10`,

  abTestingParams: {
    subjectType: 'User',
    status: {
      enrolled: 'Enrolled'
    },
    apiTimeout: 500,
    variations: {
      fullScreenCatalogVariation: 1
    }
  },

  defaultMetadata: {
    numberOfCatalogItemsToDisplayOnSplash: 25,
    numberOfCatalogItemsToDisplayOnSplashOnPhone: 15
  },

  categories: {
    featured: 0
  },

  subCategories: {
    allFeaturedItems: 0
  },

  queryNames: {
    category: 'Category',
    subcategory: 'Subcategory',
    pageNumber: 'PageNumber',
    catalogContext: 'CatalogContext',
    sortType: 'SortType',
    sortAggregation: 'SortAggregation',
    sortCurrency: 'SortCurrency',
    currencyType: 'CurrencyType',
    gears: 'Gears',
    resultsPerPage: 'ResultsPerPage',
    creatorId: 'CreatorID',
    creatorName: 'CreatorName',
    keyword: 'Keyword',
    pxMin: 'PxMin',
    pxMax: 'PxMax',
    includeNotForSale: 'IncludeNotForSale',
    legendExpanded: 'LegendExpanded'
  },

  queryValueIsInt: [
    'category',
    'subcategory',
    'catalogcontext',
    'sorttype',
    'sortaggregation',
    'sortcurrency',
    'currencytype',
    'gears',
    'creatorid',
    'pxmin',
    'pxmax'
  ],

  itemTypes: {
    bundle: 'Bundle',
    asset: 'Asset'
  },
  priceStatus: {
    free: 'Free',
    noResellers: 'NoResellers'
  },
  itemRestrictionTypes: {
    thirteenPlus: 'ThirteenPlus',
    limitedUnique: 'LimitedUnique',
    limited: 'Limited',
    rthro: 'Rthro',
    dynamicHead: 'Live',
    collectible: 'Collectible'
  },
  itemRestrictionIcons: {
    thirteenPlus: 'icon-thirteen-plus-label',
    limited: 'icon-limited-label',
    limitedUnique: 'icon-limited-unique-label',
    thirteenPlusLimited: 'icon-thirteen-plus-limited-label',
    thirteenPlusLimitedUnique: 'icon-thirteen-plus-limited-unique-label',
    rthroLabel: 'icon-rthro-label',
    rthroLimitedLabel: 'icon-rthro-limited-label',
    dynamicHead: 'icon-default-dynamichead',
    collectible: 'icon-limited-unique-label'
  },
  itemStatusClasses: {
    New: 'status-new',
    Sale: 'status-sale',
    XboxExclusive: 'status-default has-text',
    AmazonExclusive: 'status-default has-text',
    GooglePlayExclusive: 'status-default has-text',
    IosExclusive: 'status-default has-text'
  },
  itemStatusHasIcons: ['SaleTimer'],
  itemStatusIcons: {
    SaleTimer: 'icon-clock'
  },
  robloxSystemUserId: 1,
  pageDirection: {
    prev: 'prev',
    next: 'next'
  },
  initializedPaginations: {
    currentPage: 0,
    isEnabled: false,
    direction: 'next',
    startPaging: false,
    previousPageCursor: null,
    nextPageCursor: null
  },
  thumbnailValidationStatus: {
    // TODO: after we are happy on new shimmer animation, move this back to Thumbnail static compoenent and apply to more other places
    error: 'Error',
    complete: 'Completed',
    inReview: 'InReview',
    pending: 'Pending',
    blocked: 'Blocked'
  },
  thumbnailValidationClasses: {
    error: 'icon-error',
    inReview: 'icon-in-review',
    pending: 'icon-pending',
    blocked: 'icon-blocked',
    placeholder: 'icon-placeholder-asset'
  },
  userTypes: {
    user: 'User',
    group: 'Group'
  },
  errorMessages: {
    categoryName: 'CatalogPageAjaxErrors',
    endpointNames: {
      getSearchItems: 'SearchItems',
      getCatalogItemDetails: 'CatalogItemDetails'
    }
  },
  englishLanguageCode: 'en',
  autocompleteQueryAmount: 10,
  autocompleteSuggestionEventData: {
    autocompleteSuggestionEventContext: 'webAvatarShop',
    autocompleteSuggestionEventTimeoutDelay: 200,
    searchAutocompleteEvent: 'searchAutocomplete',
    searchTextTrimEvent: 'searchTextTrim',
    catalogSearchEvent: 'catalogSearch',
    searchClearEvent: 'searchClear',
    searchSuggestionClickedEvent: 'searchSuggestionClicked',
    pageName: 'Avatar Shop',
    searchBox: 'avatarShopDesktop',
    searchType: 'avatarshopsearch',
    suggestionSource: 'server'
  },
  layeredClothingSubcategories: [59, 58, 62, 61, 60, 65, 63, 64],
  topics: {
    categoriesToShowTopics: [1, 15],
    maxTopicsToRequest: 40,
    numberOfItemsToSend: 10,
    topicCarouselId: 'topic-carousel',
    topicCarouselScrollValue: 500
  },
  topicEventData: {
    topicEventContext: 'webAvatarShop',
    topicSelectEventName: 'selectCatalogTopic',
    topicDeselectEventName: 'deselectCatalogTopic',
    topicClearEventName: 'clearAllTopics'
  },
  itemTypeIds: {
    asset: 1,
    bundle: 2
  },
  topUp: {
    resetTime: 604800000,
    robuxThreshold: 50,
    displayCategories: [1]
  }
};

catalogModule.constant('catalogConstants', catalogConstants);

export default catalogConstants;
