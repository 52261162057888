import { TranslationResourceProvider } from 'Roblox';
import angular from 'angular';

const catalog = angular
  .module('catalog', ['robloxApp', 'cursorPagination', 'infiniteScroll', 'thumbnails'])
  .config([
    '$locationProvider',
    'languageResourceProvider',
    ($locationProvider, languageResourceProvider) => {
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
      });

      const translationProvider = new TranslationResourceProvider();
      const catalogResources = translationProvider.getTranslationResource('Feature.Catalog');

      languageResourceProvider.setTranslationResources([catalogResources]);
    }
  ]);

export default catalog;
